import Request from '@/utils/request';

// 1.获取当前用户关联的服务商列表
export function findSpUserList() {
    return Request('get', '/api/user/userMode/findSpUserList')
}

// 2.获取当前用户关联的部门列表
export function findDeptList(data) {
    return Request('post', '/api/user/userMode/findDeptList', data)
}

// 3.获取当前用户关联的角色列表
export function findRoleList(data) {
    return Request('post', 'api/user/userMode/findRoleList', data)
}

// 保存角色修改
export function saveMode(data) {
    return Request('post', '/api/user/userMode/saveMode', data)
}

// 編輯用戶信息
export function editUserInfo(data) {
    return Request('post', '/api/user/profile/editUser', data)
}

// 绑定微信
export function wechat(data) {
    return Request('post', '/api/wechat/bind', data)
}

// 绑定微信
export function clearWechat() {
    return Request('get', '/api/wechat/unbind')
}

