<template>
  <div id="myselfUser">
    <Back title="个人资料"></Back>
    <main>
      <section class="top">
        <div class="top-l">
          <b>昵称</b>
          <span>{{ (userInfo && userInfo.fullName) || "暂无" }}</span>
        </div>
        <van-uploader :after-read="headUp">
          <van-image
            round
            width="68"
            height="68"
            :src="(userInfo && userInfo.headPicUrl) || headImgSrc"
          />
        </van-uploader>
      </section>
      <section class="middle">
        <van-cell-group>
          <van-cell title="手机号" :value="userInfo && userInfo.mobile" />
          <van-cell title="性别" :value="userInfo && sexObj[userInfo.sex]" />
          <van-cell title="人脸授权" value="审核中" />
        </van-cell-group>
      </section>
      <section class="face-up">
        <div class="face-title">请上传正面照（用于人脸设备授权）</div>
        <div class="face-btn">
          <van-uploader :after-read="faceUp">
            <van-button icon="smile" type="info" size="small"
              >人脸上传</van-button
            >
          </van-uploader>
        </div>
      </section>
      <section class="face-pic">
        <van-image
          width="350"
          height="400"
          :src="userInfo && userInfo.facePicUrl"
        />
      </section>
    </main>
  </div>
</template>

<script>
import Back from "@/components/back";
import OSS from "ali-oss";
import Exif from "exif-js";
import { Toast } from "vant";
import { getUserInfo } from "@/api/mine/mine";
import { getUploadToken, getFileId } from "@/api/common/common";
import { editUserInfo } from "@/api/myself/myself";
export default {
  components: { Back },
  data() {
    return {
      headImgSrc: require("../../assets/images/myself/head.png"),
      userInfo: "",
      sexObj: {
        0: "男",
        1: "女",
        2: "未知",
      },
    };
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    // 【函数】随机生成文件名
    random_string(len) {
      len = len || 10;
      let chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
      let maxPos = chars.length;
      let pwd = "";
      for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return pwd;
    },

    // 【请求】获取用户信息
    getUserInfo() {
      getUserInfo().then((res) => {
        this.userInfo = res.data;
      });
    },

    // 【请求】修改用户信息
    editUserInfo() {
      const {
        nickname,
        headPicFileId,
        headPicUrl,
        sex,
        mobile,
        facePicFileId,
        facePicUrl,
      } = this.userInfo;
      let data = {
        nickname,
        headPicFileId,
        headPicUrl,
        sex,
        mobile,
        facePicFileId,
        facePicUrl,
      };
      editUserInfo(data).then((res) => {
        if (res.success) {
          Toast("修改完成");
        }
      });
    },

    // 【监听】人脸上传
    faceUp(file) {
      let _this = this;
      file.status = "uploading";
      file.message = "上传中...";
      // 获取上传密钥
      getUploadToken().then((res) => {
        const { accessKeyId, accessKeySecret, securityToken } =
          res.data.credentials;
        const client = new OSS({
          accessKeyId,
          accessKeySecret,
          stsToken: securityToken,
          bucket: "static-hiyunyu-com", //存储空间名称
          region: "oss-cn-hangzhou", //申请oss服务时的区域
        });

        //去获取拍照时的信息，解决拍出来的照片旋转问题
        const Orientation = "";
        Exif.getData(file, function () {
          Orientation = Exif.getTag(this, "Orientation");
        });

        const File = file.file;
        const reader = new FileReader();
        reader.readAsDataURL(File);
        reader.onload = function () {
          const image = new Image();
          image.src = this.result;
          image.onload = function () {
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");
            // 图片原始尺寸
            const originWidth = this.width;
            const originHeight = this.height;
            // 最大尺寸限制
            const maxWidth = 480;
            const maxHeight = 640;
            let targetWidth = originWidth;
            let targetHeight = originHeight;
            if (originWidth > maxWidth && originHeight > maxHeight) {
              targetWidth = maxWidth;
              targetHeight = maxHeight;
            } else if (originWidth > maxWidth && originHeight < maxHeight) {
              targetWidth = originHeight;
              targetHeight = originHeight;
            } else if (originWidth < maxWidth && originHeight > maxHeight) {
              targetWidth = originWidth;
              targetHeight = originWidth;
            }

            // 图片压缩旋转
            canvas.width = targetWidth;
            canvas.height = targetHeight;
            context.clearRect(0, 0, targetWidth, targetHeight); // 清除画布
            switch (Orientation) {
              case "":
                context.drawImage(image, 0, 0, targetWidth, targetHeight);
                break;
              case 1:
                context.drawImage(image, 0, 0, targetWidth, targetHeight);
                break;
              case 3: //需要180度旋转
                context.rotate(Math.PI);
                context.drawImage(
                  image,
                  -targetWidth,
                  -targetHeight,
                  targetWidth,
                  targetHeight
                );
                break;
              case 6: //需要顺时针（向左）90度旋转
                canvas.width = targetHeight;
                canvas.height = targetWidth;
                context.rotate(Math.PI / 2);
                context.drawImage(
                  image,
                  0,
                  -targetHeight,
                  targetWidth,
                  targetHeight
                );
                break;
              case 8: //需要逆时针（向右）90度旋转
                canvas.width = targetWidth;
                canvas.height = targetHeight;
                context.rotate((3 * Math.PI) / 2);
                context.drawImage(
                  image,
                  -targetWidth,
                  0,
                  targetWidth,
                  targetHeight
                );
                break;
              default:
                context.drawImage(image, 0, 0, targetWidth, targetHeight);
            }

            const type = "image/jpeg";
            //将canvas元素中的图像转变为DataURL
            const dataurl = canvas.toDataURL(type);
            //抽取DataURL中的数据部分，从Base64格式转换为二进制格式
            const bin = atob(dataurl.split(",")[1]);
            //创建空的Uint8Array
            const buffer = new Uint8Array(bin.length);
            //将图像数据逐字节放入Uint8Array中
            for (let i = 0; i < bin.length; i++) {
              buffer[i] = bin.charCodeAt(i);
            }

            // 图片上传
            let fileName =
              _this.random_string(6) +
              "_" +
              new Date().getTime() +
              "." +
              File.name.split(".").pop();
            let date = (new Date().toLocaleDateString() + "").replace(
              /\//g,
              ""
            );
            let blob = new Blob([buffer.buffer], { type }); //利用Uint8Array创建Blob对象
            client
              .multipartUpload("upload/" + date + "/" + fileName, blob, {
                progress: function (percentage) {
                  if (percentage) {
                    file.status = "done";
                    file.message = "上传成功";
                  }
                },
              })
              .then((res) => {
                let url =
                  "http://static-hiyunyu-com.oss-cn-hangzhou.aliyuncs.com/" +
                  res.name;
                getFileId(url).then((res) => {
                  if (res.success) {
                    file.fileId = res.data;
                    file.url = url;
                    _this.userInfo.facePicUrl = url;
                    _this.userInfo.facePicFileId = res.data;
                    _this.editUserInfo();
                  }
                });
              })
              .catch(() => {
                Toast("上传失败");
              });
          };
        };
      });
    },

    // 【监听】头像上传
    headUp(file) {
      let _this = this;
      file.status = "uploading";
      file.message = "上传中...";
      // 获取上传密钥
      getUploadToken().then((res) => {
        const { accessKeyId, accessKeySecret, securityToken } =
          res.data.credentials;
        const client = new OSS({
          accessKeyId,
          accessKeySecret,
          stsToken: securityToken,
          bucket: "static-hiyunyu-com", //存储空间名称
          region: "oss-cn-hangzhou", //申请oss服务时的区域
        });

        //去获取拍照时的信息，解决拍出来的照片旋转问题
        const Orientation = "";
        Exif.getData(file, function () {
          Orientation = Exif.getTag(this, "Orientation");
        });

        const File = file.file;
        const reader = new FileReader();
        reader.readAsDataURL(File);
        reader.onload = function () {
          const image = new Image();
          image.src = this.result;
          image.onload = function () {
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");
            // 图片原始尺寸
            const originWidth = this.width;
            const originHeight = this.height;
            // 最大尺寸限制
            const maxWidth = 480;
            const maxHeight = 640;
            let targetWidth = originWidth;
            let targetHeight = originHeight;
            if (originWidth > maxWidth && originHeight > maxHeight) {
              targetWidth = maxWidth;
              targetHeight = maxHeight;
            } else if (originWidth > maxWidth && originHeight < maxHeight) {
              targetWidth = originHeight;
              targetHeight = originHeight;
            } else if (originWidth < maxWidth && originHeight > maxHeight) {
              targetWidth = originWidth;
              targetHeight = originWidth;
            }

            // 图片压缩旋转
            canvas.width = targetWidth;
            canvas.height = targetHeight;
            context.clearRect(0, 0, targetWidth, targetHeight); // 清除画布
            switch (Orientation) {
              case "":
                context.drawImage(image, 0, 0, targetWidth, targetHeight);
                break;
              case 1:
                context.drawImage(image, 0, 0, targetWidth, targetHeight);
                break;
              case 3: //需要180度旋转
                context.rotate(Math.PI);
                context.drawImage(
                  image,
                  -targetWidth,
                  -targetHeight,
                  targetWidth,
                  targetHeight
                );
                break;
              case 6: //需要顺时针（向左）90度旋转
                canvas.width = targetHeight;
                canvas.height = targetWidth;
                context.rotate(Math.PI / 2);
                context.drawImage(
                  image,
                  0,
                  -targetHeight,
                  targetWidth,
                  targetHeight
                );
                break;
              case 8: //需要逆时针（向右）90度旋转
                canvas.width = targetWidth;
                canvas.height = targetHeight;
                context.rotate((3 * Math.PI) / 2);
                context.drawImage(
                  image,
                  -targetWidth,
                  0,
                  targetWidth,
                  targetHeight
                );
                break;
              default:
                context.drawImage(image, 0, 0, targetWidth, targetHeight);
            }

            const type = "image/jpeg";
            //将canvas元素中的图像转变为DataURL
            const dataurl = canvas.toDataURL(type);
            //抽取DataURL中的数据部分，从Base64格式转换为二进制格式
            const bin = atob(dataurl.split(",")[1]);
            //创建空的Uint8Array
            const buffer = new Uint8Array(bin.length);
            //将图像数据逐字节放入Uint8Array中
            for (let i = 0; i < bin.length; i++) {
              buffer[i] = bin.charCodeAt(i);
            }

            // 图片上传
            let fileName =
              _this.random_string(6) +
              "_" +
              new Date().getTime() +
              "." +
              File.name.split(".").pop();
            let date = (new Date().toLocaleDateString() + "").replace(
              /\//g,
              ""
            );
            let blob = new Blob([buffer.buffer], { type }); //利用Uint8Array创建Blob对象
            client
              .multipartUpload("upload/" + date + "/" + fileName, blob, {
                progress: function (percentage) {
                  if (percentage) {
                    file.status = "done";
                    file.message = "上传成功";
                  }
                },
              })
              .then((res) => {
                let url =
                  "http://static-hiyunyu-com.oss-cn-hangzhou.aliyuncs.com/" +
                  res.name;
                getFileId(url).then((res) => {
                  if (res.success) {
                    file.fileId = res.data;
                    file.url = url;
                    _this.userInfo.headPicUrl = url;
                    _this.userInfo.headPicFileId = res.data;
                    _this.editUserInfo();
                  }
                });
              })
              .catch(() => {
                Toast("上传失败");
              });
          };
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/theme";
#myselfUser {
  height: 100%;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.top {
  display: flex;
  justify-content: space-between;
  padding: 0.15rem;
  border-bottom: 0.15rem solid #f1f2f6;
  align-items: center;
  .top-l {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

// 人脸上传部分
.face-up {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.15rem 0;
  padding: 0 0.16rem;
  .face-title {
    color: #999;
    font-size: 0.14rem;
  }
}

.face-pic {
  display: flex;
  justify-content: center;
  margin-bottom: 0.2rem;
}
</style>